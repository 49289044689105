import React from "react";
import { Container, Col, Row, Nav, NavItem, Collapse } from "reactstrap";
import { NavLink, Outlet } from "react-router-dom";

const Root = () => {
  const [collapsed, setCollapsed] = React.useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <>
      <Container className="vh-100">
        <Row className="mb-5">
          <Col className="d-flex justify-content-between align-items-baseline p-4">
            <div className="position-relative">
              <h1
                style={{ fontFamily: "'Bitter', serif" }}
                className="mb-0 fs-2"
              >
                Renato Nouman
              </h1>
              <h2
                className="fs-6 mt-0 position-absolute"
                style={{ fontFamily: "'Montserrat', serif", fontWeight: 300 }}
              >
                pintor e escultor
              </h2>
            </div>
            <Nav
              className="d-sm-flex gap-5 d-none"
              style={{
                fontFamily: "'Montserrat', serif",
                fontWeight: 400,
                color: "black",
              }}
            >
              <NavItem>
                <NavLink
                  className={({ isActive }) =>
                    `text-black link-offset-3 link-offset-3-hover ${
                      !isActive && "link-underline-opacity-0"
                    } link-underline-opacity-75-hover link-underline-dark`
                  }
                  to="/"
                >
                  Artista
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={toggleNavbar}
                  className={({ isActive }) =>
                    `text-black link-offset-3 link-offset-3-hover ${
                      !isActive && "link-underline-opacity-0"
                    } link-underline-opacity-75-hover link-underline-dark`
                  }
                  to="/pintura"
                >
                  Pintura
                </NavLink>
              </NavItem>

              {/* <NavItem>
                <NavLink
                onClick={toggleNavbar}
                  className={({ isActive }) =>
                    `text-black link-offset-3 link-offset-3-hover ${
                      !isActive && "link-underline-opacity-0"
                    } link-underline-opacity-75-hover link-underline-dark`
                  }
                  to="/sculpture"
                >
                  Escultura
                </NavLink>
              </NavItem> */}
            </Nav>
            <div className="d-sm-none align-self-end">
              <button
                className="d-flex gap-2 flex-column border-0 bg-white"
                onClick={toggleNavbar}
              >
                <div
                  style={{
                    height: "1px",
                    width: "30px",
                    backgroundColor: "black",
                  }}
                />
                <div
                  style={{
                    height: "1px",
                    width: "30px",
                    backgroundColor: "black",
                  }}
                />
                <div
                  style={{
                    height: "1px",
                    width: "30px",
                    backgroundColor: "black",
                  }}
                />
              </button>
              <Collapse isOpen={!collapsed} navbar>
                <Nav
                  className="gap-5 position-absolute bg-white"
                  style={{
                    fontFamily: "'Montserrat', serif",
                    fontWeight: 400,
                    color: "black",
                    width: "100vw",
                    position: "absolute !important",
                    left: 0,
                    right: 0,
                    height: "100%",
                    padding: "36px",
                    paddingLeft: "24px",
                    top: "80px",
                  }}
                  vertical
                >
                  <NavItem>
                    <NavLink
                      onClick={toggleNavbar}
                      className={({ isActive }) =>
                        `text-black link-offset-3 link-offset-3-hover ${
                          !isActive && "link-underline-opacity-0"
                        } link-underline-opacity-75-hover link-underline-dark`
                      }
                      to="/"
                    >
                      Artista
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      onClick={toggleNavbar}
                      className={({ isActive }) =>
                        `text-black link-offset-3 link-offset-3-hover ${
                          !isActive && "link-underline-opacity-0"
                        } link-underline-opacity-75-hover link-underline-dark`
                      }
                      to="/pintura"
                    >
                      Pintura
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>
          </Col>
        </Row>
        <Row className="pt-5">
          <Outlet />
        </Row>
        <Row className="mt-5">
          <Copyright />
        </Row>
      </Container>
    </>
  );
};

export default Root;

function Copyright() {
  return (
    <p
      style={{
        fontSize: "13px",
        bottom: 0,
        left: "16px",
        fontFamily: "'Montserrat', serif",
        fontWeight: 400,
      }}
      className="text-body-tertiary mt-6"
    >
      &copy; {new Date().getFullYear()} | Renato Nouman
    </p>
  );
}
