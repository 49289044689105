import React from "react";
import { Col } from "reactstrap";
import { Nav, NavItem } from "reactstrap";
import { NavLink, Outlet } from "react-router-dom";
import { paintings } from "../paintings";

export const Painting = () => {
  return (
    <>
      <Col xs="12" md="3" className="d-flex justify-content-end">
        <Nav
          vertical
          className="d-flex gap-2 me-5"
          style={{
            fontFamily: "'Montserrat', serif",
            fontWeight: 400,
            fontStyle: "italic",
            color: "black",
            fontSize: "14px",
            marginBottom: "48px",
          }}
        >
          {paintings.map((painting) => (
            <NavItem className="text-end">
              <NavLink
                className={({ isActive }) =>
                  `text-black text-decoration-none ${isActive && "fw-bold"}`
                }
                to={painting.path}
              >
                {painting.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Col>
      <Col
        xs="12"
        md="9"
        className="pe-sm-5 d-flex flex-column flex-sm-row align-items-center align-items-sm-end"
        style={{ fontFamily: "'Montserrat', serif", fontWeight: 400 }}
      >
        <Outlet />
      </Col>
    </>
  );
};
