import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/custom.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/Root";
import { About } from "./routes/About";
import { Painting } from "./routes/Painting";
import { paintings } from "./paintings";

// export const links = [
//   {
//     label: "instagram",
//     href: "https://www.instagram.com/renato.nouman/",
//   },
//   {
//     label: "email",
//     href: "renato.nouman@gmail.com",
//   },
// ];

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/",
          element: <About />,
        },
        {
          path: "pintura",
          element: <Painting />,
          children: paintings,
        },
      ],
    },
  ]);

  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
