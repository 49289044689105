import React from "react";
import crucificacao2 from "../src/assets/crucificacao-ii.jpg";
import anima from "../src/assets/anima-captiva.JPG";
import vita from "../src/assets/vita-in-mortis.JPG";

export const paintings = [
  {
    path: "crucificação-ii",
    element: (
      <>
        <img
          src={crucificacao2}
          className="w-100 w-sm-50"
          alt="Crucificação II - photograph"
        />
        <div
          className="ms-sm-5 d-flex flex-column justify-content-end align-items-center align-items-sm-start mt-4 mb-5 mt-sm-0 mb-sm-0"
          style={{ fontSize: "14px" }}
        >
          <p className="m-0 mb-2">
            <span className="fst-italic">Crucificação II,</span> 2023
          </p>
          <p className="m-0">Óleo sobre tela</p>
          <p className="m-0">170cm x 120cm</p>
        </div>
      </>
    ),
    title: "Crucificação II",
  },
  {
    path: "vita-in-mortis",
    element: (
      <>
        <img
          src={vita}
          className="w-100 w-sm-50"
          alt="Vita in Mortis / Cortex Vacuus I - photograph"
        />
        <div
          className="ms-sm-5 d-flex flex-column justify-content-end align-items-center align-items-sm-start mt-4 mb-5 mt-sm-0 mb-sm-0"
          style={{ fontSize: "14px" }}
        >
          <p className="m-0 mb-2">
            <span className="fst-italic">
              Vita in Mortis / Cortex Vacuus I,
            </span>{" "}
            2023
          </p>
          <p className="m-0">Óleo sobre tela</p>
          <p className="m-0">200 x 140cm</p>
        </div>
      </>
    ),
    title: "Vita in Mortis / Cortex Vacuus I",
  },
  {
    path: "anima-captiva",
    element: (
      <>
        <img
          src={anima}
          className="w-100 w-sm-50"
          alt="Anima captiva - photograph"
        />
        <div
          className="ms-sm-5 d-flex flex-column justify-content-end align-items-center align-items-sm-start mt-4 mb-5 mt-sm-0 mb-sm-0"
          style={{ fontSize: "14px" }}
        >
          <p className="m-0 mb-2">
            <span className="fst-italic">Anima Captiva,</span> 2023
          </p>
          <p className="m-0">Óleo sobre tela</p>
          <p className="m-0">200 x 140cm</p>
        </div>
      </>
    ),
    title: "Anima captiva",
  },
];
