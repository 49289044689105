import React from "react";
import { Col } from "reactstrap";

import img from "../assets/photo.png";

export const About = () => {
  return (
    <>
      <Col
        lg="3"
        className="d-flex justify-content-center justify-content-sm-end"
      >
        <img
          src={img}
          alt="Renato Nouman's face photograph"
          style={{ width: "180px", height: "180px" }}
          className="me-md-5 mb-5"
        />
      </Col>
      <Col
        lg="7"
        className="p-4 p-md-1 pe-lg-5"
        style={{ fontFamily: "'Montserrat', serif", fontWeight: 400 }}
      >
        <>
          Renato Nouman é pintor e escultor. Vive e trabalha em São Paulo e
          mantém seu atelier na Zona Sul da cidade. Iniciou-se na pintura e
          escultura no atelier do artista e professor José Paulo de Latorre em
          2007. Desde então, desenvolve seu trabalho como forma de representar
          os estados psíquicos (ethos) e tensões existenciais do ser humano no
          espaço contemporâneo. <br />
          <br />
          Ganhou medalhas de bronze e prata com pinturas inscritas na Grande
          Exposição de Arte Bunkyo, em 2013 e 2014, respectivamente. Formou-se
          em design gráfico no Centro Universitário Belas Artes, trabalhou como
          designer em publicidade e propaganda, e mais recentemente como
          desenvolvedor de software para startups. <br />
          <br />
          Nasceu e cresceu na capital onde desenvolveu um diálogo com o mundo
          interior (da casa e de si mesmo). Neste mundo interior, adquiriu um
          intenso interesse por filosofia durante a leitura de livros como:
          Musashi, de Eiji Yoshikawa, e a Insustentável Leveza do Ser, de Milan
          Kundera. Sua inclinação filosófica se revela na obra que procura
          expressar aspectos da vida contemporânea por meio da representação da
          figura humana habitando um espaço pictórico que simula essa vivência
          em ambientes internos. <br />
          <br />A melancolia, o aprisionamento, a solidão e a arrogância figuram
          no trabalho, não como profecia catastrófica, mas como registro
          sincero, e talvez desafiador, do ânimo contemporâneo que leva o ser
          humano a existir na angústia. O trabalho busca se apresentar como um
          espelho que desvela alguma verdade incômoda sobre nós mesmos.
        </>
      </Col>
    </>
    //<Wrapper>
    // <Main>
    //     <Title>Renato Nouman</Title>
    //     <Subtitle>
    //       {language === "por" ? "Pintor e escultor." : "Painter and sculptor."}
    //     </Subtitle>
    //     <Paragraph>
    //       {language === "por" ? (
    //         <>
    //           Renato Nouman é pintor e escultor. Vive e trabalha em São Paulo e
    //           mantém seu atelier na Zona Sul. Iniciou-se na pintura e escultura
    //           no atelier do artista e professor José Paulo de Latorre em 2007.
    //           Desde então desenvolve seu trabalho como forma de representar os
    //           estados psíquicos (ethos) e tensões existenciais do ser humano no
    //           espaço contemporâneo.
    //           <br />
    //           <br />
    //           Ganhou medalhas de bronze e prata com pinturas inscritas na Grande
    //           Exposição de Arte Bunkyo, em 2013 e 2014, respectivamente.
    //           Formou-se em design gráfico no Centro Universitário Belas Artes,
    //           trabalhou como designer em publicidade e propaganda e uma empresa
    //           tradicional de consultoria, e mais recentemente como desenvolvedor
    //           de software para startups.
    //           <br />
    //           <br />
    //           Nasceu e cresceu na cidade grande e passava a maior parte dentro
    //           de casa, onde desenvolveu um diálogo com o mundo interior (da casa
    //           e de si mesmo). Neste mundo interior, desde cedo adquiriu um
    //           intenso interesse por filosofia durante a leitura de livros como
    //           Musashi, de Eiji Yoshikawa, e a Insustentável Leveza do Ser, de
    //           Milan Kundera. Essa sua inclinação filosófica e introspectiva se
    //           revela na obra que procura expressar aspectos da vida
    //           contemporânea por meio da representação da figura humana habitando
    //           um espaço pictórico que simula essa vivência em ambientes
    //           internos.
    //           <br />
    //           <br />A melancolia, o aprisionamento, a solidão e a arrogância
    //           figuram no trabalho, não como profecia catastrófica, mas como
    //           registro sincero, e talvez desafiador, do ânimo contemporâneo que
    //           leva o ser humano a existir na angústia. O trabalho busca se
    //           apresentar como um espelho que desvela alguma verdade incômoda
    //           sobre nós mesmos e talvez venha a incitar uma reflexão profunda.
    //         </>
    //       ) : (
    //         <>
    //           Renato Nouman is a painter and sculptor. He lives and works in São
    //           Paulo and maintains his studio in the South Zone. He started in
    //           painting and sculpture at the artist and teacher José Paulo de
    //           Latorre's studio in 2007. Since then, he has developed his work as
    //           a way of representing the psychic states (ethos) and existential
    //           tensions of human beings in contemporary space.
    //           <br />
    //           <br />
    //           He won bronze and silver medals with paintings entered in the
    //           Bunkyo Art Grand Exhibition in 2013 and 2014, respectively. He
    //           graduated in graphic design from Centro Universitário Belas Artes,
    //           worked as a designer in advertising and a traditional consulting
    //           firm, and more recently as a software developer for startups.
    //           <br />
    //           <br />
    //           He was born and raised in the big city and spent most of his time
    //           indoors, where he developed a dialogue with the inner world (of
    //           the house and himself). In this inner world, he acquired an
    //           intense interest in philosophy from an early age while reading
    //           books such as Musashi, by Eiji Yoshikawa, and The Unbearable
    //           Lightness of Being, by Milan Kundera. His philosophical and
    //           introspective inclination is revealed in his work, which seeks to
    //           express aspects of contemporary life through the representation of
    //           the human figure inhabiting a pictorial space that simulates this
    //           experience in indoor environments.
    //           <br />
    //           <br />
    //           Melancholy, imprisonment, loneliness, and arrogance appear in his
    //           work not as a catastrophic prophecy but as a sincere and perhaps
    //           challenging record of the contemporary mood that leads human
    //           beings to exist in anguish. The work seeks to present itself as a
    //           mirror that unveils some uncomfortable truth about ourselves and
    //           may incite a deep reflection.
    //         </>
    //       )}
    //     </Paragraph>
    //     <AnchorList>
    //       {links.map((link) => (
    //         <AnchorListItem
    //           href={link.label !== "email" && link.href}
    //           target="blank"
    //           rel="noopener noreferrer"
    //           alt={`Renato Nouman's ${link.label}`}
    //           onClick={() =>
    //             link.label === "email" && setMailVisible(!mailVisible)
    //           }
    //         >
    //           {link.label === "email" && mailVisible ? link.href : link.label}
    //         </AnchorListItem>
    //       ))}
    //     </AnchorList>
    //     <LanguageButtonGroup>
    //       <Button
    //         onClick={() => setLanguage("por")}
    //         active={language === "por"}
    //       >
    //         PT
    //       </Button>{" "}
    //       |{" "}
    //       <Button onClick={() => setLanguage("en")} active={language === "en"}>
    //         EN
    //       </Button>
    //     </LanguageButtonGroup>
    //   </Main>
    // </Wrapper>
  );
};
